<template>
  <a-card :bordered="false" class="card-area">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-for="item in tabList" :key="item" :label="item" :name="item"/>
    </el-tabs>
    <view-company-info v-show="activeName=='基本信息'"/>
    <biz-car-brand-settings v-show="activeName=='主营车型'"/>
  </a-card>
</template>

<script>
import ViewCompanyInfo from "./ViewCompanyInfo";
import BizCarBrandSettings from "./BizCarBrandSettings";

export default {
  name: "companyInfo",
  components: {
    BizCarBrandSettings,
    ViewCompanyInfo
  },
  data() {
    return {
      tabList: ['基本信息', '主营车型'],
      activeName: '基本信息',
    }
  },
  methods: {
    handleClick(value) {
      this.activeName = value.name
    },
  }
}
</script>

<style scoped lang="less">

</style>
